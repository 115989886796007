:root {
  --red: #ff7588;
  --gray: #c3c3c3;
  --blue: #00aeef;
  --white: #ffffff;
  --green: #16d39a;
  --black: #000000;
  --orange: #f78800;
  --lightRed: #ffedf0;
  --darkBlue: #2f3854;
  --lightGray: #F1F1F1;
  --lightBlue: #effbff;
  --lightBlack: #7d7d7d;
  --lightGreen: #d6fff3;
  --lightOrange: #fff3e4;
  --lightDarkBlue: #f1f1f1;
  --superLightBlue: #f4fcff;
  --skeletonsLightBlue: #f4fcff5e;
  --seeThroughLightBlue: #f4fcffdb;
  --strongRed: rgba(255, 0, 0, 0.8);
  
  --speed: 500ms;
  --border-radius: 8px;

  --font-size: 14px !important;
  --font-size-bigger: 16px !important;
  --font-size-medium: 12px !important;

  --toastify-toast-width: auto !important; 
  --toastify-toast-min-height: 40px !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto";
}

body {
  display: flex;
  flex-wrap: wrap;
  background-color: var(--superLightBlue);
  /* background-image: url(./assests/bg.png); */
}
#root {
  margin: auto;
}
.HM-container,
.HM-sidebar,
.HM-appbar {
  transition: width .3s linear !important;
}
.loadingSpinnerContainer {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5000;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.insurer-select-product {
  display: flex;
  margin: 25px !important;
}
.insurer-select-product img {
  width: 45px;
  margin: auto;
}
.page,
.fade-in {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
.slide-bottom {
	-webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.swing-out {
  -webkit-animation: swing-out-top-bck 0.45s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
	        animation: swing-out-top-bck 0.45s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
}
@-webkit-keyframes swing-out-top-bck {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
  }
}
@keyframes swing-out-top-bck {
  0% {
    opacity: 1;
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
  }
}
p {
  overflow-wrap: break-word;
  font-size: var(--font-size);
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  color: var(--blue);
  text-decoration: none;
}
a:hover {
  opacity: 0.8;
}
svg {
  margin: auto;
  cursor: 'pointer';
  font-size: medium;
  color: var(--blue);
}
svg:hover {
  opacity: 0.8
}
svg path {
  transform: none !important;
}
input {
  padding: 8px !important;
  font-size: var(--font-size) !important;
}
input[type='number'] {
  text-align: center;
}
.content { 
  height: 0px;
  overflow: hidden;
  transition: height ease 0.3s;
}
.HM-appbar .content { 
  height: 0px;
  overflow: hidden;
  transition: height ease 0.3s;
  box-shadow: 0 7px 8px rgb(0 0 0 / 12%);
  -moz-box-shadow: 0 7px 8px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 7px 8px rgb(0 0 0 / 12%);
}
.content.showDial {
  height: 100px;
}
.opened {
  color: var(--orange) !important;
}
.closed {
  color: var(--lightBlack) !important;
}
.opened svg:last-child {
  transform: rotate(0deg)
}
.closed svg:last-child {
  transform: rotate(90deg)
}
.MuiFormControlLabel-label {
  font-size: var(--font-size);
  color: var(--lightBlack);
}
.toogle-button span.MuiFormControlLabel-label {
  font-size: var(--font-size);
  float: left;
  margin: auto;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  font-weight: 700;
  padding: 1px 5px;
  border-radius: 5px;
  letter-spacing: 0.00938em;
}
.toogle-button.active span {
  color: var(--green);
}
.toogle-button.inactive span {
  color: var(--red);
}
.toogle-button.active span.MuiFormControlLabel-label {
  background-color: var(--lightGreen);
}
.toogle-button.inactive span.MuiFormControlLabel-label {
  background-color: var(--lightRed);
}
.toogle-button.active span.MuiSwitch-track {
  background-color: var(--green);
}
.toogle-button.inactive span.MuiSwitch-track {
  background-color: var(--red);
}
.HM-radio-button.negative .Mui-checked svg {
  color: var(--red);
}
.HM-radio-button.positive .Mui-checked svg {
  color: var(--green);
}

.small-navigation .toogle-button span.MuiFormControlLabel-label {
  font-size: 12px;
  padding: 5px 10px;
}
.small-navigation label.toogle-button {
  margin: 10px 23px auto auto !important;
}
 
.operator-order .toogle-button.inactive span {
  color: var(--white);
}
.operator-order .toogle-button.inactive span.MuiFormControlLabel-label {
  color: var(--darkBlue);
  background-color: var(--white);
}
.operator-order .toogle-button.inactive span.MuiSwitch-track {
  background-color: var(--white);
}
.store-sale .toogle-button.inactive span {
  color: var(--blue);
}
.store-sale .toogle-button.inactive span.MuiFormControlLabel-label {
  color: var(--blue);
  background-color: var(--lightBlue);
}
.store-sale .toogle-button.inactive span.MuiSwitch-track {
  background-color: var(--blue);
}
.close-x-button {
  animation: cssAnimation 0s 1s forwards;
  visibility: hidden;
}
.late-display {
  animation: cssAnimation 0s 1s forwards;
  visibility: hidden;
}
@keyframes cssAnimation {
  to   { visibility: visible; }
}

/* Before Login Pages */
input.otp_filed {
  outline: none;
  background-color: red;
  border-width: 0 !important;
  font-size: var(--font-size-bigger) !important;
}
input.otp_filed:focus{
  outline: none;
}

/* Login Page */
/* .HM-Login iframe {
  width: 100% !important;
} */

/* HM Scroll Top */
.HM-scroll-top {
  box-shadow: 0 7px 8px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 7px 8px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 7px 8px rgba(0, 0, 0, 0.12);
}

/* For DataTable */
table th:nth-child() {
  display: none;
}
table td:nth-child() {
  display: none;
}
.MuiMenuItem-gutters {
  font-size: var(--font-size);
}
#passwordLength {
  text-align: center;
  padding: 5px !important;
  font-size: var(--font-size-medium);
}
input:disabled {
  -webkit-text-fill-color: var(--lightBlack) !important;
}
label,
#select-label {
  font-size: var(--font-size);  
  padding: 0 !important;
  margin: 0 !important;
}
label.MuiInputLabel-shrink,
#select-label.MuiInputLabel-shrink {
  margin-top: 0px !important;
  margin-left: -3px !important;
}
.super-mini-image {
  width: 30px;
  height: 30px;
  margin: auto 0;
  border-radius: 8px;
}
.super-mini-logo {
  width: 30px;
  height: 30px;
  margin: auto 0;
  border-radius: 8px;
}
.super-mini-logo p {
  padding: 2px;
  font-size: 18px !important;
}
.mini-image {
  width: 40px;
  height: 40px;
  margin: auto 0;
  border-radius: 8px;
}
.mini-logo {
  width: 40px;
  height: 40px;
  margin: auto 0;
  border-radius: 8px;
}.mini-logo p {
  font-size: 27px !important;
}
.mini-logo p {
  font-size: 27px !important;
}
.small-image {
  width: 50px;
  height: 50px;
  margin: auto 0;
  border-radius: 8px;
}
.medium-image {
  width: 70px;
  height: 70px;
  margin: auto 0;
  border-radius: 8px;
}
.small-logo {
  width: 50px;
  height: 50px;
  margin: auto 0;
  border-radius: 8px;
}
.user-image {
  width: 50px;
  height: 50px;
  margin: auto 0;
  border-radius: 50%;
}
.not-allowed input {
  cursor: not-allowed;
}
button.responsive {
  min-width: 50px;
}
/* For Toastify */
.Toastify__toast {
  padding: 5px !important;
}
.Toastify__toast-body {
  font-size: var(--font-size);
}

/* For Insurer creation & update */
.highlightOption {
  background-color: var(--lightBlue) !important;
  color: var(--lightBlack) !important;
}
.multiSelectContainer li:hover {
  background-color: var(--blue) !important;
  color: var(--white) !important;
}

/* For Store creation & update */
.supplier-creation-form label,
.store-creation-form label {
  z-index: 0 !important;
}
label.MuiInputLabel-root {
  background-color: var(--white);
  padding: 0 5px !important;
  border-radius: 5px;
  margin-top: -10px !important;
}
label.MuiInputLabel-root.MuiInputLabel-shrink {
  margin: auto !important;
}
input.location-search-input {
  width: 100%;
  margin-top: 5px;
  border-width: 1px;
  border-radius: 5px;
  border-color: var(--lightGray);
}
input.wrong-input {
  color: var(--red) !important;
  background-color: var(--lightRed) !important;
}
.react-datepicker-popper {
  z-index: 50000 !important;
}
.react-datepicker-wrapper {
  width: 100%;
  margin: 0 auto auto auto;
}
.react-datepicker-wrapper input {
  width: 100%;
  border-radius: 5px; 
  text-align: center;
  padding: 10px !important;
  border: 1px solid #999;
}
.react-datepicker-wrapper input:focus {
  border: 2px solid blue !important;
}
.HM-dropdown .dropdown .arrow {
  top: 25px
}
.HM-dropdown.listing .dropdown .MuiFormControl-root {
  margin: 15px 0 2px 0;
}
.supplier-creation-form .HM-DatePicker,
.store-creation-form .HM-DatePicker {
  background-color: var(--white)
}
.stock-date .HM-DatePicker {
  background-color: var(--white);
}
/* For Printing */
.print-data-table {
  background-image: none !important;
  margin: 20px
}
.print-data-table .print-page-title { 
  display: block !important; 
}
.print-data-table table {
  margin-top: 20px;
  font-size: 12px !important;
}
.print-data-table table thead {
  background-color: var(--darkBlue) !important;
}
.print-data-table .action,
.print-data-table .select,
.print-data-table .up-down-arrow {
  display: none;
}

.print-data-table tr.even {
  background-color: var(--white) !important;
}
.print-data-table th {
  color: var(--white);
} 
.print-data-table td {
  color: var(--darkBlue);
}

/* POS Printing */
.print-performa {
  height: 100vh;
  background-color: var(--white) !important;
}
.print-receipt .button,
.print-performa .print,
.print-performa .button {
  display: none;
}
.print-performa .print-performa-title {
  display: block !important;
}
.print-receipt .print-receipt-body,
.print-receipt .print-receipt-title,
.print-performa .print-performa-title {
  display: block !important;
}
.print-receipt {
  height: 100vh;
  background-color: var(--white) !important;
}

/* HM appbar */
.HM-appbar.fixed {
  margin-top: 0;
  position: fixed;
  top: 0 !important;
  box-shadow: 0 7px 8px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 7px 8px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 7px 8px rgba(0, 0, 0, 0.12);
}
.hide-header {
  -webkit-animation: hide-header 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: hide-header 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes hide-header {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px);
  }
}
@keyframes hide-header {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px);
  }
}
.HM-appbar.fixed.hide-header {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}

/* HM Slider */
.HM-slider .slider {
  overflow-x: scroll;
  white-space: normal;
  display: -webkit-box;
  scrollbar-width: none;
  scroll-behavior: smooth;
  -webkit-box-orient: horizontal;
}
.HM-slider .slider::-webkit-scrollbar {
  display: none;
}

/* Animated Slider */
/* .HM-partners {
  border: 5px solid red;
}
.animated-slider .one {
  border: 5px solid green;
}
.animated-slider .two {
  border: 5px solid yellow;
}
.animated-slider div a {
  border: 5px solid cyan;
} */
.animated-slider {
  transform: translate(0, 0%);
}
.animated-slider a {
  margin: auto;
}
.animated-slider a img {
  height: 50px !important;
}
.animated-slider.mid-normal a img {
  height: 40px !important;
}
.animated-slider.medium a img {
  height: 35px !important;
}
.animated-slider.small a img {
  height: 35px !important;
}
.animated-slider.normal:hover,
.animated-slider.medium:hover ,
.animated-slider.small:hover  {
  animation-play-state: paused;
}

.animated-slider.normal {
  width: 450%;
  animation: animated-normal 35s linear infinite;
}
@keyframes animated-normal {
  0% {
    left: 0; 
  }
  100% {
    left: -225%;
  }
}

.animated-slider.mid-normal {
  width: 600%;
  animation: animated-mid-normal 35s linear infinite;
}
@keyframes animated-mid-normal {
  0% {
    left: 0; 
  }
  100% {
    left: -300%;
  }
}

.animated-slider.medium {
  width: 800%;
  animation: animated-medium 20s linear infinite;
}
@keyframes animated-medium {
  0% {
    left: 0; 
  }
  100% {
    left: -400%;
  }
}

.animated-slider.small {
  width: 900%;
  animation: animated-small 20s linear infinite;
}
@keyframes animated-small {
  0% {
    left: 0; 
  }
  100% {
    left: -450%;
  }
}



/* Product card */
.product-card-title {
  max-height: 3rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: normal;
}
/* Admin Product Display */
.description p {
  width: 100%;
  margin: auto;
  font-size: var(--font-size);
}
.description p span {
  font-size: inherit !important;
  background-color: inherit !important;
}
.description div {
  font-size: var(--font-size);
  margin: auto 0;
}
/* Drag and Drop */
.drag-drop {
  width: 100%;
  height: 15vh;
  border-radius: var(--border-radius);
  padding: 10px;
  cursor: pointer;
  display: flex;
  margin: auto;
}
.drag-drop p {
  width: 100%;
  text-align: center;
  margin: auto;
}
.drag-active {
  background-color: var(--lightGreen);
}

/* For Landing page */
.landing.top-section {
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-position-x: 90% !important;
  background-image: url(./assests/HM-landing-image-p.png);
}
.HM-search.landing button {
  width: 35px;
  height: 35px;
  margin: auto -5px auto auto !important;
}
.HM-search.landing button svg {
  width: 30px;
  height: 25px;
}
.category.active,
.category.active:hover {
  color: var(--white);
  background-color: var(--orange);
}

/* Cart page */
.cart-product.quantity input,
.pos-item.quantity input {
  font-weight: 500;
  border-radius: 8px;
  text-align: center;
  height: auto !important;
  font-size: 10px !important;
  padding: 0px 0px !important;
  background-color: var(--white);
}
.HM-cart-info .HM-radio-button span.MuiTypography-root.MuiFormControlLabel-label {
  font-size: var(--font-size);
}
.HM-cart-info fieldset {
  display: none;
}
.HM-cart-info .hide {
  display: none;
}
.empty-cart {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.HM-spinner.cart-spinner svg {
  color: var(--orange)
}

/* For public product page */
.public-product.quantity input {
  font-weight: 500;
  border-radius: 8px;
  text-align: center;
  background-color: var(--lightBlue);
}
.public-product.quantity input::-webkit-outer-spin-button,
.public-product.quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.pos-item.quantity fieldset,
.cart-product.quantity fieldset,
.public-product.quantity fieldset {
  display: none;
}
.change-store-button p {
  font-weight: 500;
}
.listing-radio-button.checked svg {
  color: var(--orange);
}
.cart-product.quantity input::-webkit-outer-spin-button,
.cart-product.quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* For public store page */
.HM-price-filter .max input::-webkit-outer-spin-button,
.HM-price-filter .max input::-webkit-inner-spin-button,
.HM-price-filter .min input::-webkit-outer-spin-button,
.HM-price-filter .min input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.HM-price-filter .max input,
.HM-price-filter .min input {
  font-weight: 500;
  text-align: left;
  border-radius: 8px;
  -moz-appearance: none;
  background-color: var(--lightBlue);
}
.HM-price-filter fieldset {
  display: none;
}
.HM-price-filter span.MuiSlider-colorPrimary {
  color: var(--orange) !important;
}

/* Checkout */
.HM-accordion.checkout::before {
  display: none;
}
.HM-accordion.checkout {
  background-color: var(--seeThroughLightBlue);
}
.HM-accordion.insurance {
  background-color: var(--skeletonsLightBlue);
}
.HM-accordion.checkout .details {
  padding: 0 10px;
}
.HM-accordion.checkout .MuiCollapse-wrapper {
  padding-bottom: 10px;
}
.HM-accordion.checkout .accordion-title  {
  text-align: center;
}
.recipient-phone-number input::-webkit-outer-spin-button, 
.recipient-phone-number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
} 
.recipient-phone-number input {
  text-align: left;
}
.radio-buttons label,
.save-insurer .HM-radio-buttons label,
.radio-buttons .HM-radio-button span.MuiTypography-root.MuiFormControlLabel-label,
.save-insurer .HM-radio-button span.MuiTypography-root.MuiFormControlLabel-label {
  font-size: var(--font-size);
}
.prescription {
  border-radius: var(--border-radius);
}
.checkout-payment-method label.MuiInputLabel-root.MuiInputLabel-shrink,
.checkout-payment-method label.MuiInputLabel-root {
  color: inherit !important;
  margin-left: -18px !important;
  background: inherit !important;
}
.checkout-payment-method label.MuiInputLabel-root {
  font-size: var(--font-size);
}
.checkout-payment-method fieldset {
  display: none !important;
}
.checkout-payment-method input[type='text'] {
  padding: 8px 8px 2px 0 !important;
  border-bottom: 1px solid var(--gray);
}
.checkout-payment-method input[type='text']:focus {
  border-bottom: 1px solid var(--blue);
}
.checkout-payment-method .error input[type='text']:focus {
  border-bottom: 1px solid var(--red);
}
.checkout-payment-method input[type='text'] {
  font-weight: 500;
  font-size: var(--font-size);
}
.checkout-payment-method .error { 
  display: none;
}
.checkout-payment-method .toogle-button span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label,
.checkout-insurerance .toogle-button span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
  display: none;
}
/* For DataTable */
th.select {
  float: left;
}
.HM-search input {
  background: inherit;
  border-width: 0px !important;
}
.HM-search input:focus-visible {
  outline: none
}
.HM-search input::placeholder {
  font-weight: 500;
}
.HM-search.data-table button {
  width: 30px;
  height: 30px;
  margin: auto 5px !important;
}
.HM-search.data-table button svg {
  width: 25px;
  height: 20px;
}
.search-drop-down {
  overflow: hidden;
  transition: height ease 0.3s;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  box-shadow: 0 7px 8px rgb(0 0 0 / 12%);
  -moz-box-shadow: 0 7px 8px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 7px 8px rgb(0 0 0 / 12%);
}
.search-drop-down.close {
  height: 0px;
}
/* For HMSelectOptions */
.select-options.landing .MuiInputBase-input {
  margin: auto;
  min-width: 40px;
  text-align: center;
  padding: 2.5px 0px 0px 0px;
}
.select-options.landing fieldset {
  border-width: 0px;
  border-color: var(--blue) !important;
}
.MuiMenuItem-root {
  min-height: 30px !important;
}
/* For DropDown Filter */
.dropdown {
  position: relative;
  color: var(--lightBlack);
  cursor: default;
}

.arrow-holder {
  cursor: pointer;
  position: absolute;
  right: 2px;
  top: 7px;
  display: block;
  padding: 8px;
  background: var(--white);
  opacity: 1;
}

.dropdown .arrow {
  top: 14px;
  width: 0;
  right: 5px;
  height: 0;
  content: " ";
  display: block;
  cursor: pointer;
  margin-top: 0.3rem;
  position: absolute;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: #999 transparent transparent;
}
.dropdown .arrow.open {
  border-width: 0 5px 5px;
  border-color: transparent transparent #999;
}
.dropdown .options {
  top: 100%;
  width: 100%;
  display: none;
  z-index: 1000;
  overflow-y: auto;
  margin-top: -1px;
  max-height: 200px;
  position: absolute;
  box-sizing: border-box;
  font-size: var(--font-size);
  background-color: var(--white);
  -webkit-overflow-scrolling: touch;
}
.dropdown .options.open {
  display: block;
}
.dropdown .option {
  display: block;
  cursor: pointer;
  padding: 8px 10px;
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
}
.dropdown.update-order-status.danger input {
  font-weight: 600;
  color: var(--red);
  background-color: var(--lightRed);
  border: 0px solid var(--red) !important;
}
.dropdown.update-order-status.completed input {
  font-weight: 600;
  color: var(--blue);
  background-color: var(--lightBlue);
  border: 0px solid var(--blue) !important;
}

/* For Jordi Text Editor */
.jodit-react-container p,
.jodit-react-container span {
  font-size: var(--font-size);
  color: var(--lightBlack);
}
.jodit-add-new-line,
.jodit-add-new-line_after {
  display: none !important;
}
.jodit-toolbar-button .jodit-icon {
  height: 8px !important;
  width: 8px !important;
}
.jodit-toolbar-button__text,
.jodit-placeholder {
  font-size: var(--font-size);
}
.jodit-toolbar-editor-collection_mode_vertical .jodit-toolbar-button {
  min-height: 20px !important;
}
.jodit-toolbar-editor-collection_size_middle.jodit-toolbar-editor-collection_mode_horizontal,
.jodit-toolbar-editor-collection_mode_horizontal {
  background-image: none !important;
  background-color: var(--white);
}
.jodit-toolbar-button,
.jodit-toolbar-button button {
  min-width: 34px !important;
  height: 20px !important;
}
.jodit-toolbar-button__button:hover:not([disabled]) {
  background-color: var(--lightBlue) !important;
}

/* For Scroll bar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--lightBlack); 
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:vertical {
  background: var(--lightBlue); 
}
::-webkit-scrollbar-thumb:horizontal {
  background: var(--blue); 
}
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}
::-webkit-scrollbar-thumb:vertical:hover {
  background: var(--blue);
}

/* for edit profile */

.edit-icon {
    color:white;
    background-color: #00AEEF;
    border-radius: 50%;
    padding: 2px;
    margin-left: -12px;
    
}
.img-input{
  display: none;
}

/* Store Applciation */
.HM-Stepper svg.Mui-active {
  color: var(--blue) !important;
}
.HM-Stepper svg.Mui-completed {
  color: var(--orange) !important;
}
.HM-Stepper input::-webkit-outer-spin-button, 
.HM-Stepper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
} 
.HM-Stepper input[type='number'] {
  text-align: left;
  padding: 8px 10px !important;
}
.HM-Stepper label.MuiInputLabel-root {
  font-size: 14px;
  padding: 2px 5px !important;
}

/* POS Landing */
.pos-item.quantity input::-webkit-outer-spin-button,
.pos-item.quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}


/* Validate Insurance */


/* MAP */
.marker {
  width: 22px;
  height: 22px;
  border-radius: 11px;
  background-size: cover;
  background-color: yellow;
  border: 3px solid darkslategrey;
  background-image: url('https://i.imgur.com/oz3r3Wq.png');
}
.marker-delivery {
  width: 22px;
  height: 22px;
  background-color: red;
}

/***multistep model **/
.finish{
  color: orange;
}

/****faqs****/
.category-card:hover,
.category-card:focus,
.category-card:visited{
  cursor: pointer;
  color: orange;
}

/***file input***/

.upload-file {
  margin-top: 1px;
  font-size: 16px ;
  width: 100%;
}

::-webkit-file-upload-button{
  color: var(--blue);
  background: #ffffff;
  padding: 5px ;
  border: 1px solid var(--blue);
  border-radius: 4px;
  outline: none;
  margin-right: 12px;
 
}

/********analytics*************/

.report-btn{
  background:  linear-gradient(177.45deg, #00AEEF -76.73%, #D99BFF 174.57%) !important;
}